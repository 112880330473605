<template>
  <div class="page template-1">
    <header class="page-header container">
      <h1>{{ translations.tcSearchSite }}</h1>
    </header>
    <b-container class="page-body">
      <b-row align-h="center">
        <b-col sm="10" class="search-col">
          <search-input @handleSearchClick='handleSearchClick' :globalSearchTerm='globalSiteSearch'
            :i18n='translations.components'></search-input>
        </b-col>
      </b-row>
      <b-row class="filter-row" align-h="center">
        <b-col sm="10" class="mt-5">
          <b-form-checkbox-group id="cbFilters" v-model="selectedFilters" name="cbFilters" plain>
            <span class="h4 mr-3 text-uppercase">{{ translations.tcFilters }}:</span>
            <b-form-checkbox value="Individual" class="mr-3">{{ translations.tcIndividuals }}</b-form-checkbox>
            <b-form-checkbox value="Organization" class="mr-3">{{ translations.tcOrganizations }}</b-form-checkbox>
            <b-form-checkbox value="Camp" class="mr-3">{{ translations.tcCamps }}</b-form-checkbox>
            <b-form-checkbox value="View" class="mr-3">{{ translations.tcPages }}</b-form-checkbox>
            <b-form-checkbox value="Document" class="mr-3">{{ translations.tcDocuments }}</b-form-checkbox>
            <b-form-checkbox value="Video" class="mr-3">{{ translations.tcVideos }}</b-form-checkbox>
            <b-form-checkbox value="Training" class="mr-3">{{ translations.tcTraining }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <b-row class="result-row">
        <b-col sm="12" class="result-col mt-5">
          <data-table v-if="showResults" :addItems="addItems" :hideHeader="true" :title="null" :tooltip="tooltip"
            :fields="fields" :items="filteredResults" :i18n="translations.components">
          </data-table>
        </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/DataTableSearch.vue'
import SearchInput from '@/components/SearchInputGlobal.vue'
import SearchService from '@/services/SearchService'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'search-site',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      searchResults: [],
      selectedFilters: [],
      searchTerm: '',
      showResults: false,
      fields: [
        {
          key: 'search_results',
          label: 'Results',
        },
      ],
      tooltip: 'Testing',
      addItems: {},
    }
  },
  async created() {
    await this.clearSearchTerm()
    await this.page_load()
  },
  methods: {
    ...mapActions({
      clearSearchTerm: 'search/clearSearchTerm',
      setGlobalSiteSearch: 'search/setGlobalSiteSearch',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async handleSearchClick(searchTerm) {
      try {
        this.setLoadingStatus(true)
        this.setGlobalSiteSearch(searchTerm)
        const results = await this.getSearchResults(searchTerm)
        const formattedResults = this.formatForDataTable(results)
        this.searchResults = formattedResults
        this.showResults = true
      } catch (error) {
        this.setLoadingStatus(true)
        console.error(`There was an error retrieving search results ${error} for module ${this.$options.name}`)
        throw error
      } finally {
        this.setLoadingStatus(false)
      }
    },
    getSearchResults(searchTerm) {
      var response = SearchService.getSearchResults(searchTerm, this.prefCulture, this.userId)
      return response
    },
    formatForDataTable(result) {
      return result.data.map((x) => {
        return {
          Address1: x['Address1'],
          AreaDirectorEmail: x['AreaDirectorEmail'],
          AreaDirectorName: x['AreaDirectorName'],
          AreaDirectorPhone: x['AreaDirectorPhone'],
          AssetParentCategory: x['AssetParentCategory'],
          CampPresidentsEmail: x['CampPresidentsEmail'],
          CampPresidentsName: x['CampPresidentsName'],
          CampPresidentsPhone: x['CampPresidentsPhone'],
          City: x['City'],
          Country: x['Country'],
          CountryAbr: x['CountryAbr'],
          CountryISO: x['CountryISO'],
          Denomination: x['Denomination'],
          Description: x['Description'],
          ExternalUrl: x['ExternalUrl'],
          FirstName: x['FirstName'],
          id: x['id'],
          ind_account_id: x['ind_account_id'],
          IsAFriend: x['IsAFriend'],
          IsAMember: x['IsAMember'],
          IsAProspect: x['IsAProspect'],
          IsCamp: x['IsCamp'],
          IsChurch: x['IsChurch'],
          IsChurchPresenter: x['IsChurchPresenter'],
          IsGideonCardFacility: x['IsGideonCardFacility'],
          IsPlacementFacility: x['IsPlacementFacility'],
          IsTheConUser: x['IsTheConUser'],
          IsWidow: x['IsWidow'],
          ItemID: x['ItemID'],
          ItemName: x['ItemName'],
          JoinDate: x['JoinDate'],
          Keywords: x['Keywords'],
          LastName: x['LastName'],
          Link: x['Link'],
          MemberChurchKey: x['MemberChurchKey'],
          MemberChurchName: x['MemberChurchName'],
          Name: x['Name'],
          OrgAddress1: x['OrgAddress1'],
          OrgAddress2: x['OrgAddress2'],
          OrgAddress3: x['OrgAddress3'],
          OrganizationName: x['OrganizationName'],
          OrganizationType: x['OrganizationType'],
          OrgEmail: x['OrgEmail'],
          OrgPhoneNumber: x['OrgPhoneNumber'],
          OrgPhoneNumberMasked: x['OrgPhoneNumberMasked'],
          OrgState: x['OrgState'],
          PreferedName: x['PreferedName'],
          SpouseaAccountID: x['SpouseaAccountID'],
          SpouseAMemeber: x['SpouseAMemeber'],
          SpouseFormalName: x['SpouseFormalName'],
          SpouseKey: x['SpouseKey'],
          State: x['State'],
          ThumbnailId: x['ThumbnailId'],
          Title: x['Title'],
          TopicId: x['TopicId'],
          TopicParentId: x['TopicParentId'],
          type: x['Type'],
          VideoParentCategory: x['VideoParentCategory'],
          VideoParentCategoryCode: x['VideoParentCategoryCode']
        }
      })
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getTranslations(),
          this.getComponentTranslations('data-table-search', 'search-input')
        ]).then((results) => {
          this.$set(this.translations, 'components', results[1])
          this.fields[0].label = this.translations.tcResults
          if (this.globalSiteSearch.length > 0) {
            this.handleSearchClick(this.globalSiteSearch)
          }
        })
      } catch (e) {
        console.error('Error in Search.vue, page_load(), ', e)
      } finally {
        this.setLoadingStatus(false)
      }
    }
  },
  computed: {
    ...mapGetters({
      globalSiteSearch: 'search/globalSiteSearch',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
    }),
    filteredResults() {
      var filteredArr = this.searchResults.map(r => r)
      if (this.selectedFilters.length > 0) {
        filteredArr = filteredArr.filter((r) => {
          var pos;
          if (r.type == "View" || r.type == "Store" || r.type == "MediaResourcePage") {
            pos = this.selectedFilters.indexOf("View")
          } else {
            pos = this.selectedFilters.indexOf(r.type)
          }
          return pos >= 0
        })
        if (this.selectedFilters.includes('Training')) {
          filteredArr = filteredArr.filter(s => !!s.Keywords && s.Keywords.toLowerCase().indexOf('training') > -1)
        }
      }

      if (this.selectedFilters.length === 1 && this.selectedFilters.includes('Training')) {
        filteredArr = this.searchResults.filter(s => !!s.Keywords && s.Keywords.toLowerCase().indexOf('training') > -1)
      }

      return filteredArr
    },
  },
  components: {
    DataTable,
    SearchInput,
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
</style>
