<template>
  <div>
    <b-row>
      <b-col class="search-col">
        <b-form-input
          :title="i18n['search-input'].tcSearch"
          id="search"
          :placeholder="i18n['search-input'].tcSearch"
          v-model="searchTerm"
          @keyup.enter="handleSearchClick"
          @change="handleSearchTermChange"
          size="lg"
          required
        >
        </b-form-input>
        <b-button variant="primary" size="lg" class="btn btn-primary" @click="handleSearchClick">
          {{ i18n['search-input'].tcSearch }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'

export default {
  props: {
    globalSearchTerm: '',
    i18n: {
      type: Object,
      default: () => {
        return {
          'search-input': {
            tcClear: 'Clear',
            tcOk: 'Ok',
            tcSearch: 'Search',
            tcSearchRequires: 'Search requires at least one term.'
          },
        }
      },
    },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  created() {
    this.searchTerm = this.globalSearchTerm
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    handleSearchTermChange() {
      this.$emit('searchTermChanged', { term: this.searchTerm, label: this.i18n['search-input'].tcSearch })
    },
    handleSearchClick() {
      if (this.searchTerm.trim() === '') {
        this.$swal({
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: '#d33',
          text: this.i18n['search-input'].tcSearchRequires,
          confirmButtonText: this.i18n['search-input'].tcOk,
        })
      } else {
        this.$emit('handleSearchClick', this.searchTerm)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.search-col {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  .btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
